// const styles: CSS.Properties = {
//     //background:"transparent",    
//     position:"fixed",
//     bottom:deviceType.isMobile() ? "1rem" : "2rem",
//     right:deviceType.isMobile() ? "1rem" : "2rem",
//     transition: "background .2s, opacity .2s linear",
//     opacity: show,
//     zIndex: 9000
// }

.back-to-top{
    position:fixed;
    bottom:2em;
    right:2em;
    transition: background .2s, opacity .2s linear;
    opacity: 0;
    z-index:9000

    i{
        font-size: 1.5em;
    }
}