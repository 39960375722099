.back-to-top {
  opacity: 0;
  z-index: 9000 i;
  z-index-font-size: 1.5em;
  transition: background .2s, opacity .2s linear;
  position: fixed;
  bottom: 2em;
  right: 2em;
}

/*# sourceMappingURL=index.82a9b01a.css.map */
